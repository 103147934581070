<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Multiselect from "@vueform/multiselect";
import axios from 'axios';

export default {
    components:{
        Layout,
        PageHeader,
        Multiselect
    },
    data() {
        return{
            data: {
              type_vehicule: "",
              immatriculation: "",
              numero_chassis: ""
            },
            vehicule: {},
            title: "Rechercher Vehicule",
            items: [
                {
                    text: "Recherche",
                    href: "/",
                },
                {
                    text: "Vehicule recherchée",
                    active: true,
                },
            ],
            spanView: false,
            tabView: true,
            tableView: false,
        }
    },
    methods: {
        async searchVehicule(){
            this.spanView = true;
            const that = this;
            const formData = new FormData();
            formData.append('type_vehicule', this.data.type_vehicule);
            formData.append('immatriculation', this.data.immatriculation);
            formData.append('numero_chassis', this.data.numero_chassis);
            try {
                const response = await axios.post('https://api.alcit.sims-technologie.com/api/v1/vehicule/search/', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': localStorage.getItem('token'),
                    },
                });
                that.vehicule = response.data;
                console.log(that.vehicule);
                that.spanView = false;
                that.tabView = false;
                that.tableView = true;
              console.log(response);
            } catch (error) {
              console.error(error);
                that.spanView = false;
                that.authError = error;
            }
        },
        detailsCar(car){
            localStorage.setItem('car', JSON.stringify(car));
            this.$router.push({ path: `/VehiculeDelail/${car.id}`});
        },
    }
}
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row" v-if="tabView">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="form-group row mb-4">
                            <div class="col-lg-12">
                                <multiselect class="form-control" v-model="data.type_vehicule" :options="[{ value: 'voiture', label: 'voiture' }, { value: 'moto', label: 'moto' }]">
                                </multiselect>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <div class="col-lg-12">
                                <input v-model="data.immatriculation" id="immatriculation" name="immatriculation" type="text"
                                    class="form-control" placeholder="Plaque d'immatriculation, Exemple: AZ-7834 RT" />
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <div class="col-lg-12">
                                <input v-model="data.numero_chassis" id="numero_chassis" name="numero_chassis" type="text"
                                    class="form-control" placeholder="numero de chassis" />
                            </div>
                        </div>
                        <div class="row justify-content-end">
                            <div class="col-md-10">
                                <button @click="searchVehicule()" type="button" class="btn btn-success"
                                    :disabled="spanView" style="float: right">
                                    <span v-if="spanView" class="spinner-border spinner-border-sm"></span>
                                    Rechercher
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row"  v-if="tableView">
            <div class="card">
              <div class="card-title">
                <a href="#" @click="tableView = false; tabView=true">Retourner sur le formulaire de recherche</a>
              </div>
                <div class="card-body">
                    <div class="table-responsive mt-3">
                        <table class="table table-nowrap table-centered mb-0 align-middle">
                            <thead class="table-light">
                                <tr>
                                    <th>Plaque véhicule</th>
                                    <th>châssis</th>
                                    <th>Marque</th>
                                    <th>Modèle</th>
                                    <th>Année</th>
                                    <th>Type</th>
                                    <th>Conduite à tenir</th>
                                    <th>Statut</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="vehicule in vehicule" :key=vehicule.id>
                                    <td>{{ vehicule.immatriculation }}</td>
                                    <td>{{ vehicule.numero_chassis }}</td>
                                    <td>{{ vehicule.marque }}</td>
                                    <td>{{ vehicule.modele }}</td>
                                    <td>{{ vehicule.annee }}</td>
                                    <td>{{ vehicule.type_vehicule }}</td>
                                    <td>{{ vehicule.conduite_a_tenir }}</td>
                                    <td>
                                        <span class="badge font-size-11 m-1" :class="{
                                            'bg-success': `${vehicule.statut}` === 'non_rechercher',
                                            'bg-danger': `${vehicule.statut}` === 'rechercher',
                                        }">{{ vehicule.statut === 'non_rechercher'? 'Retrouvé' : 'Recherché' }}</span>
                                    </td>
                                    <td>
                                        <button class="btn btn-warning btn-sm me-2 w-xs" @click="detailsCar(vehicule)">Informations</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div v-if="Object.keys(vehicule).length == 0" class="text-center mt-3">
                            <h5>Aucune Voiture Trouvée</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>